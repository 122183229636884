import { ethers } from 'ethers'
import axios from 'axios'


// 估算GAS消耗
const MORALIS_API_BASE = 'https://deep-index.moralis.io/api/v2.2'
const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjRkYmRkZmE5LTYxZDUtNDg1My1hYzRhLTY5NTRiYTBhODFjZiIsIm9yZ0lkIjoiNDEwOTQ0IiwidXNlcklkIjoiNDIyMzAyIiwidHlwZUlkIjoiNWUxZDRiN2YtZTc3NS00ZDQ0LTk4MTAtYjNjNzg2Yjk5MWM4IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjgzODYzNTAsImV4cCI6NDg4NDE0NjM1MH0.BaYrSrphPqhXpDr813aVJiu6j0hQ7CZmghJ6yqnhOmg'

// 代币所需要的接口
export const useCollectionLogic = (provider: ethers.providers.JsonRpcProvider, selectedChain: 'ETH' | 'BSC') => {
  const getGasPrice = async (): Promise<string> => {
    const gasPrice = await provider.getGasPrice()
    return ethers.utils.formatUnits(gasPrice, 'gwei')
  }

  // 原生币换算成GAS费价格
  const getNativeTokenPrice = async (): Promise<number> => {
    const chainId = selectedChain === 'ETH' ? '0x1' : '0x38'
    const nativeTokenAddress = selectedChain === 'ETH' 
      ? '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
      : '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'

    const exchange = selectedChain === 'ETH' ? 'uniswapv2' : 'pancakeswapv2'

    try {
      const response = await axios.get(`${MORALIS_API_BASE}/erc20/${nativeTokenAddress}/price`, {
        params: {
          chain: chainId,
          exchange: exchange
        },
        headers: {
          'X-API-Key': MORALIS_API_KEY
        }
      })
      return response.data.usdPrice
    } catch (error) {
      console.error('获取原生代币价格失败:', error)
      throw error
    }
  }

  const calculateGasCost = async (gasPrice: string, gasLimit: number): Promise<{ nativeCost: string; usdCost: string }> => {
    const gasCost = ethers.utils.parseUnits(gasPrice, 'gwei').mul(gasLimit)
    const nativeCost = ethers.utils.formatEther(gasCost)
    
    const nativeTokenPrice = await getNativeTokenPrice()
    const usdCost = (parseFloat(nativeCost) * nativeTokenPrice).toFixed(2)
    
    return { nativeCost, usdCost }
  }

  const transferTokens = async (
    from: string, 
    to: string, 
    amount: string, 
    privateKey: string, 
    tokenAddress: string | null,
    gasPrice: string,
    gasLimit: number,
    decimals: number,
    nonce: number
  ) => {
    const wallet = new ethers.Wallet(privateKey, provider)
    let tx
  
    const gasPriceWei = ethers.utils.parseUnits(gasPrice, 'gwei')
    const amountBigNumber = ethers.utils.parseUnits(amount, decimals)
  
    console.log(`Attempting to transfer ${amount} tokens (${amountBigNumber.toString()} in wei) from ${from} to ${to}`)
  
    const txOptions = {
      gasPrice: gasPriceWei,
      gasLimit,
      nonce 
    }
  
    if (tokenAddress) {
      
      const abi = ['function transfer(address to, uint256 amount) returns (bool)']
      const contract = new ethers.Contract(tokenAddress, abi, wallet)
      tx = await contract.transfer(to, amountBigNumber, txOptions)
    } else {
      
      tx = await wallet.sendTransaction({
        to,
        value: amountBigNumber,
        ...txOptions
      })
    }
  
    console.log(`Transaction hash: ${tx.hash}`)
    return tx
  }

  return {
    getGasPrice,
    calculateGasCost,
    transferTokens
  }
}