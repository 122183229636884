import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { ButtonPrimary, ButtonGray } from '../Button';
import { AutoColumn } from '../Column';
import Card from '../Card';
import Web3Status from '../Web3Status';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import MultiSenderAddressInput from './MultiSenderAddressInput';
import MultiSenderConfirmation from './MultiSenderConfirmation';
import TokenApproval from './TokenApproval';

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 0.2rem;
`;

const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.bg1};
  padding: 1.5rem;
  border-radius: 20px;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;


const Title = styled.h2`
  color: ${({ theme }) => theme.text2};
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const StepCircle = styled.div<{ active: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.primary1 : theme.bg3)};
  color: ${({ theme, active }) => (active ? theme.white : theme.text3)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

const StepConnector = styled.div`
  width: 50px;
  height: 2px;
  background-color: ${({ theme }) => theme.bg3};
  margin: 0 10px;
`;

const StepPrompt = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.primary2};
  font-weight: bold;
`;


const InputLabel = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 0.5rem;
`;

const InfoText = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.primary2};
  padding: 0.25rem 0.35rem;
  border-radius: 0.5rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem auto 0;
  width: 50%;
  gap: 1rem;

      @media (max-width: 480px) {
    width: 80%;
  }
`;

const StyledGrayButton = styled(ButtonGray)`
`;

const TokenInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem 0 1rem;
`;

const TokenInfoGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
`;

const TokenInfoLabel = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: 0.9rem;
`;

const TokenInfoValue = styled.span`
  color: ${({ theme }) => theme.text1};
  font-size: 0.9rem;
`;


const TitleAndControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
`;

const BalanceText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 0.75rem 0 0.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.2rem;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`;

const AddressInput = styled.input`
  flex: 1;
  height: 2.5rem;
  padding: 0 0.75rem;
  border-radius: 8px 0 0 8px;
  font-size: 0.875rem;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-right: none;

  &:focus {
    border-color: ${({ theme }) => theme.primary1};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }

    @media (max-width: 480px) {
    width: 90%;
  }
`;

const ToggleButton = styled.button<{ active: boolean }>`
  padding: 0 1rem;
  border: 1px solid ${({ theme, active }) => (active ? theme.primary1 : theme.primary2)};
  border-left: none;
  border-radius: 0 8px 8px 0;
  background-color: ${({ theme, active }) => (active ? theme.primary1 : theme.primary2)};
  color: ${({ theme }) => theme.white}; // 保持文字颜色始终为白色，提高可读性
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.primary1 : theme.primary3)};
    border-color: ${({ theme, active }) => (active ? theme.primary1 : theme.primary3)};
  }

  &:active {
    background-color: ${({ theme }) => theme.primary1};
    border-color: ${({ theme }) => theme.primary1};
  }
`;


const MultiSender: React.FC = () => {
    const [step, setStep] = useState(1);
    const [tokenAddress, setTokenAddress] = useState('');
    const [recipients, setRecipients] = useState<{ address: string; amount: string }[]>([]);
    const [tokenInfo, setTokenInfo] = useState({ name: '', decimals: 0, balance: '0' });
    const [userNativeBalance, setUserNativeBalance] = useState<string>('0');
    const [isValidTokenAddress, setIsValidTokenAddress] = useState(false);
    const [isValidRecipients, setIsValidRecipients] = useState(false);
    const [isBalanceSufficient, setIsBalanceSufficient] = useState(true);
    const { account, chainId, library } = useWeb3React();
    const [isNativeCoin, setIsNativeCoin] = useState(false);


    const nativeCurrency = chainId === 1 ? 'ETH' : 
                       chainId === 11155111 ? 'SepoliaETH' :
                       'BNB';

    //合约授权
    const BSC_CONTRACT_ADDRESS = "0x5D00661EA3c9b8f095520573b9B940B6fEbcfD8b";
    const ETH_CONTRACT_ADDRESS = "0xB803b0E5E7457B135085E896FD7A3398b266cd43";
    //test
    const SEPOLIA_CONTRACT_ADDRESS = "0x6CF74d5c417d1beBBf4969F1734e1c27c4E39709";
    const contractAddress = chainId === 1 ? ETH_CONTRACT_ADDRESS : 
                        chainId === 11155111 ? SEPOLIA_CONTRACT_ADDRESS :
                        BSC_CONTRACT_ADDRESS;

    const [approvedAmount, setApprovedAmount] = useState('');
    
  
    useEffect(() => {
      if (account && library) {
        library.getBalance(account).then((balance: ethers.BigNumber) => {
          setUserNativeBalance(ethers.utils.formatEther(balance));
        });
      }
    }, [account, library]);

    useEffect(() => {
        if (step === 2) {
            const totalAmount = recipients.reduce((sum, r) => sum + parseFloat(r.amount), 0);
            setIsBalanceSufficient(parseFloat(tokenInfo.balance) >= totalAmount);
        }
    }, [step, recipients, tokenInfo.balance]);

    //获取授权额度
    const formatApprovedAmount = (amount: string) => {
      if (amount === '无需授权') return amount;
      try {
        const bigAmount = ethers.BigNumber.from(amount);
        const formattedAmount = ethers.utils.formatUnits(bigAmount, tokenInfo.decimals);
        return parseFloat(formattedAmount).toFixed(6);
      } catch (error) {
        console.error('格式化授权金额失败:', error);
        return amount;
      }
    };
  
    const checkApprovedAmount = useCallback(async () => {
      if (isNativeCoin) {
        setApprovedAmount('无需授权');
        return;
      }
    
      if (!account || !library || !tokenAddress) {
        setApprovedAmount('0');
        return;
      }
    
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ['function allowance(address owner, address spender) view returns (uint256)'],
        library.getSigner()
      );
    
      try {
        const allowance = await tokenContract.allowance(account, contractAddress);
        setApprovedAmount(allowance.toString());
      } catch (error) {
        console.error('获取授权额度失败:', error);
        setApprovedAmount('0');
      }
    }, [isNativeCoin, account, library, tokenAddress, contractAddress]);
  
    useEffect(() => {
      checkApprovedAmount();
    }, [checkApprovedAmount, step, tokenAddress, isNativeCoin]);
    
      const totalSendAmount = useMemo(() => {
        if (!isValidTokenAddress || tokenInfo.decimals === 0) {
          return '0';
        }
        try {
          const total = recipients.reduce((sum, r) => {
            // 使用 parseUnits 来处理小数，但首先检查金额是否为空或零
            const amount = r.amount && parseFloat(r.amount) !== 0 
              ? ethers.utils.parseUnits(r.amount, tokenInfo.decimals) 
              : ethers.BigNumber.from(0);
            return sum.add(amount);
          }, ethers.BigNumber.from(0));
          // 转换回带小数点的字符串
          return ethers.utils.formatUnits(total, tokenInfo.decimals);
        } catch (error) {
          console.error('Error calculating totalSendAmount:', error);
          return '0';
        }
      }, [isValidTokenAddress,recipients, tokenInfo.decimals]);
    
      const needsApproval = useMemo(() => {
        if (isNativeCoin) return false;
        if (approvedAmount === '无需授权') return false;
        if (!approvedAmount || approvedAmount === '0') return true;
        if (!totalSendAmount || parseFloat(totalSendAmount) === 0) return false;
      
        try {
          const approvedBigNumber = ethers.BigNumber.from(approvedAmount);
          const requiredAmount = ethers.utils.parseUnits(totalSendAmount, tokenInfo.decimals);
          return approvedBigNumber.lt(requiredAmount);
        } catch (error) {
          console.error('Error in needsApproval calculation:', error);
          return true; // 如果出现错误，假设需要授权
        }
      }, [isNativeCoin, approvedAmount, totalSendAmount, tokenInfo.decimals]);
      //授权额度END
  
    const fetchTokenInfo = useMemo(() => async () => {
        if (isNativeCoin) {
          setTokenInfo({
            name: chainId === 1 ? 'ETH' : 
            chainId === 11155111 ? 'SepoliaETH' : 
            'BNB',
            decimals: 18,
            balance: userNativeBalance
          });
          setIsValidTokenAddress(true);
        } else if (tokenAddress && ethers.utils.isAddress(tokenAddress) && library && account) {
        try {
          const tokenContract = new ethers.Contract(
            tokenAddress,
            ['function name() view returns (string)', 'function decimals() view returns (uint8)', 'function balanceOf(address) view returns (uint256)'],
            library.getSigner()
          );
  
          const [name, decimals, balance] = await Promise.all([
            tokenContract.name(),
            tokenContract.decimals(),
            tokenContract.balanceOf(account)
          ]);
  
          setTokenInfo({
            name,
            decimals,
            balance: ethers.utils.formatUnits(balance, decimals)
          });
          setIsValidTokenAddress(true);
        } catch (error) {
          console.error('获取代币信息失败:', error);
          setTokenInfo({ name: '未知', decimals:  0, balance: '0' });
          setIsValidTokenAddress(false);
        }
      } else {
        setIsValidTokenAddress(false);
      }
    }, [tokenAddress, library, chainId, userNativeBalance, isNativeCoin , account]);
  
    useEffect(() => {
      fetchTokenInfo();
    }, [fetchTokenInfo]);
  
    const handleNextStep = () => {
      if (step < 3 && isValidTokenAddress && isValidRecipients) setStep(step + 1);
      checkApprovedAmount();
    };
  
    const handlePreviousStep = () => {
      if (step > 1) setStep(step - 1);
      checkApprovedAmount();
    };
  
    const getStepPrompt = (currentStep: number) => {
      switch (currentStep) {
        case 1: return "请填写合约地址/收件人地址";
        case 2: return "请确认发送信息";
        case 3: return "开始发送";
        default: return "";
      }
    };
  
    const isNextStepEnabled = isValidTokenAddress && isValidRecipients;
  
    return (
        <Wrapper>
          <StyledCard>
            <TitleAndControlsWrapper>
              <TitleSection>
                <Title>Multi-Sender</Title>
              </TitleSection>
              <HeaderControls>
                <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                  {account && userNativeBalance ? (
                    <BalanceText>
                      {parseFloat(userNativeBalance).toFixed(2)} {
                        chainId === 1 ? "ETH" : 
                        chainId === 97 ? "TBNB" : 
                        chainId === 56 ? "BNB" : 
                        chainId === 11155111 ? "Sepolia" : 
                        "TEST ETH"
                      }
                    </BalanceText>
                  ) : null}
                  <Web3Status />
                </AccountElement>
              </HeaderControls>
            </TitleAndControlsWrapper>

            <TopSection>
            <StepIndicator>
              <StepCircle active={step >= 1}>1</StepCircle>
              <StepConnector />
              <StepCircle active={step >= 2}>2</StepCircle>
              <StepConnector />
              <StepCircle active={step >= 3}>3</StepCircle>
            </StepIndicator>
            <StepPrompt>{getStepPrompt(step)}</StepPrompt>
          </TopSection>
  
          {step === 1 && (
          <AutoColumn gap="1rem">
            <InputGroup>
            <InputLabel>代币地址：</InputLabel>
        <InputWrapper>
        <AddressInput
          value={isNativeCoin ? '' : tokenAddress}
          onChange={(e) => setTokenAddress(e.target.value)}
          placeholder={`当前${
            chainId === 1 ? 'ETH' : 
            chainId === 11155111 ? 'Sepolia' :
            'BSC'
          } 网络 ，原生代币请点击按钮👉`}
          disabled={isNativeCoin}
        />
        <ToggleButton
          active={isNativeCoin}
           onClick={() => {
             setIsNativeCoin(!isNativeCoin);
             if (!isNativeCoin) {
                setTokenAddress('');
                setTokenInfo({
                  name: chainId === 1 ? 'ETH' :
                  chainId === 11155111 ? 'SepoliaETH' : 
                  'BNB',
                 decimals: 18,
                 balance: userNativeBalance
                });
                  setIsValidTokenAddress(true);
                  } else {
                   setTokenInfo({ name: '未知', decimals: 0, balance: '0' });
                   setIsValidTokenAddress(false);
                  }
                 }}
                 >
                    {chainId === 1 ? 'ETH' :
                    chainId === 11155111 ? 'SepoliaETH' :
                    'BNB'}
                 </ToggleButton>
               </InputWrapper>
              </InputGroup>
  
              <TokenInfoContainer>
                <TokenInfoGroup>
                  <TokenInfoLabel>代币名称</TokenInfoLabel>
                  <TokenInfoValue>{tokenInfo.name}</TokenInfoValue>
                </TokenInfoGroup>
                <TokenInfoGroup>
                  <TokenInfoLabel>精度</TokenInfoLabel>
                  <TokenInfoValue>{tokenInfo.decimals}</TokenInfoValue>
                </TokenInfoGroup>
                <TokenInfoGroup>
                  <TokenInfoLabel>您的余额</TokenInfoLabel>
                  <TokenInfoValue>{tokenInfo.balance}</TokenInfoValue>
                </TokenInfoGroup>
              </TokenInfoContainer>
  
              <MultiSenderAddressInput
              recipients={recipients}
              setRecipients={setRecipients}
              isPreviewMode={false}
              setIsValidInput={setIsValidRecipients}
            />

            <ButtonGroup>
              {isNextStepEnabled ? (
                <ButtonPrimary onClick={handleNextStep}>下一步</ButtonPrimary>
              ) : (
                <StyledGrayButton disabled>下一步</StyledGrayButton>
              )}
            </ButtonGroup>
          </AutoColumn>
        )}
  
      {step === 2 && (
          <AutoColumn gap="1rem">
            <TokenInfoContainer>
              <TokenInfoGroup>
                <TokenInfoLabel>发送地址总数</TokenInfoLabel>
                <TokenInfoValue>{recipients.length}</TokenInfoValue>
              </TokenInfoGroup>
              <TokenInfoGroup>
                <TokenInfoLabel>构建交易数</TokenInfoLabel>
                <TokenInfoValue>{Math.ceil(recipients.length / 200)}</TokenInfoValue>
              </TokenInfoGroup>
              <TokenInfoGroup>
                <TokenInfoLabel>您的余额</TokenInfoLabel>
                <TokenInfoValue>{tokenInfo.balance}</TokenInfoValue>
              </TokenInfoGroup>
              <TokenInfoGroup>
                <TokenInfoLabel>发送总金额</TokenInfoLabel>
                <TokenInfoValue>
                {totalSendAmount}
                </TokenInfoValue>
              </TokenInfoGroup>
              <TokenInfoGroup>
                <TokenInfoLabel>授权额度</TokenInfoLabel>
                <TokenInfoValue>{formatApprovedAmount(approvedAmount)}</TokenInfoValue>
              </TokenInfoGroup>
            </TokenInfoContainer>

            {!isBalanceSufficient && (
                  <InfoText style={{ color: 'red' }}>
                    您的代币余额不足以完成此次交易。请检查您的余额并调整发送金额。
                  </InfoText>
                )}

            <MultiSenderAddressInput
              recipients={recipients}
              setRecipients={setRecipients}
              isPreviewMode={true}
              setIsValidInput={setIsValidRecipients}
            />

            <ButtonGroup>
              <StyledGrayButton onClick={handlePreviousStep}>返回</StyledGrayButton>
              {isBalanceSufficient && (
                isNativeCoin ? (
                  <ButtonPrimary onClick={handleNextStep}>
                    确认
                  </ButtonPrimary>
                ) : (
                  <TokenApproval
                    tokenAddress={tokenAddress}
                    spenderAddress={contractAddress}
                    amount={totalSendAmount}
                    onApprovalSuccess={checkApprovedAmount}
                    tokenDecimals={tokenInfo.decimals}
                    isNativeCoin={isNativeCoin}
                    isInputValid={isValidRecipients}
                    renderButton={(onClick, disabled, text) => (
                      needsApproval ? (
                        <ButtonPrimary onClick={onClick} disabled={disabled}>
                          {text}
                        </ButtonPrimary>
                      ) : (
                        <ButtonPrimary onClick={handleNextStep}>
                          确认
                        </ButtonPrimary>
                      )
                    )}
                  />
                )
              )}
              {!isBalanceSufficient && (
                <StyledGrayButton disabled>余额不足</StyledGrayButton>
              )}
            </ButtonGroup>
          </AutoColumn>
        )}
  
          {step === 3 && (
            <AutoColumn gap="1rem">
              <MultiSenderConfirmation
              tokenAddress={isNativeCoin ? '' : tokenAddress}
              tokenName={isNativeCoin ? nativeCurrency : tokenInfo.name}
              tokenBalance={isNativeCoin ? userNativeBalance : tokenInfo.balance}
              recipients={recipients}
              tokenDecimals={isNativeCoin ? 18 : tokenInfo.decimals}
              handlePreviousStep={handlePreviousStep}
              isNativeCoin={isNativeCoin}
              nativeCurrency={nativeCurrency}
              />
            </AutoColumn>
          )}
        </StyledCard>
      </Wrapper>
    );
  };
  
  export default MultiSender;