import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Card from '../../components/Card'
import { Input } from '../NumericalInput'
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';

const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0.2rem;
`

const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.bg1};
  padding: 1.5rem;
  border-radius: 20px;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.text2};
  margin: 0 0 1rem 0;
`

const TableWrapper = styled.div`
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  overflow: hidden;
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
`

const TableHeader = styled.th`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bg2};
  text-align: left;
  padding: 0.75rem 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-weight: 500;
  z-index: 1;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.6rem 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
    padding: 0.5rem 0.3rem;
  }
`

const TableCell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  background-color: ${({ theme }) => theme.bg1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.4rem 0.3rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
    padding: 0.3rem 0.2rem;
  }
`

const TableContainer = styled.div`
  max-height: 300px;
  height: 300px;
  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const InfoText = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.red1};
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 0.5rem;
  white-space: pre-wrap;
  text-align: center;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 1rem;
  }
`


const ValidationStatus = styled.span<{ isValid: boolean }>`
  color: ${({ isValid }) => isValid ? '#28a745' : '#dc3545'};
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 150px;
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};

  &:focus {
    border-color: ${({ theme }) => theme.primary1};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`

const CustomButton = styled.button<{ $primary?: boolean; $generated?: boolean }>`
  height: 40px;
  padding: 0 15px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  
  ${({ $primary, $generated, theme }) => 
    $primary
      ? `
        background-color: ${theme.primary1};
        color: white;
        border: none;
        &:hover {
          background-color: ${theme.primary2};
        }
      `
      : $generated
        ? `
          background-color: ${theme.blue1};
          color: white;
          border: none;
          &:hover {
            background-color: ${theme.blue1};
          }
        `
        : `
          background-color: ${theme.bg3};
          color: ${theme.text1};
          border: 1px solid ${theme.bg4};
          &:hover {
            background-color: ${theme.bg4};
          }
        `
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.red1};
  font-size: 0.9rem;
  margin-top: 0.5rem;
`

declare global {
  interface Window {
    Web3: any;
  }
}

const BulkWalletGeneration: React.FC = () => {
  const [walletCount, setWalletCount] = useState(1);
  const [generatedWallets, setGeneratedWallets] = useState<{ address: string; privateKey: string; isValid: boolean }[]>([]);
  const [isWeb3Loaded, setIsWeb3Loaded] = useState(false);
  const [copyAddressSuccess, setCopyAddressSuccess] = useState(false);
  const [copyPrivateKeySuccess, setCopyPrivateKeySuccess] = useState(false);
  const [error, setError] = useState('');
  const [isGenerated, setIsGenerated] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/web3@latest/dist/web3.min.js';
    script.async = true;
    script.onload = () => setIsWeb3Loaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const generateWallets = useCallback(() => {
    if (!isWeb3Loaded) return;
    
    if (walletCount <= 0) {
      setError('请输入有效的钱包生成数量');
      return;
    }

    setError('');
    const web3 = new window.Web3();
    const wallets = Array.from({ length: walletCount }, () => {
      const privateKey = web3.utils.randomHex(32);
      const address = web3.eth.accounts.privateKeyToAccount(privateKey).address;
      const isValid = web3.eth.accounts.privateKeyToAccount(privateKey).address.toLowerCase() === address.toLowerCase();
      return { address, privateKey, isValid };
    });

    setGeneratedWallets(wallets);
    setIsGenerated(true);
  }, [walletCount, isWeb3Loaded]);

  const handleWalletCountChange = (value: string) => {
    const parsedValue = parseInt(value);
    setWalletCount(isNaN(parsedValue) ? 0 : parsedValue);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      console.error('Failed to copy: ', err);
      return false;
    }
  };

  const copyAllAddresses = async () => {
    const addresses = generatedWallets.map(wallet => wallet.address).join('\n');
    const success = await copyToClipboard(addresses);
    setCopyAddressSuccess(success);
    setTimeout(() => setCopyAddressSuccess(false), 2000);
  };

  const copyAllPrivateKeys = async () => {
    const privateKeys = generatedWallets.map(wallet => wallet.privateKey).join('\n');
    const success = await copyToClipboard(privateKeys);
    setCopyPrivateKeySuccess(success);
    setTimeout(() => setCopyPrivateKeySuccess(false), 2000);
  };

  return (
    <Wrapper>
      <StyledCard>
        <Title>EVM钱包生成</Title>

        <TableWrapper>
          <Table>
            <colgroup>
              <col style={{ width: '5%' }} />
              <col style={{ width: '35%' }} />
              <col style={{ width: '55%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <thead>
              <tr>
                <TableHeader>ID</TableHeader>
                <TableHeader>地址</TableHeader>
                <TableHeader>私钥</TableHeader>
                <TableHeader>地址验证</TableHeader>
              </tr>
            </thead>
          </Table>
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '55%' }} />
                <col style={{ width: '9%' }} />
              </colgroup>
              <tbody>
                {generatedWallets.map((wallet, index) => (
                  <tr key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{wallet.address}</TableCell>
                    <TableCell>{wallet.privateKey}</TableCell>
                    <TableCell>
                    <ValidationStatus isValid={wallet.isValid}>
              {wallet.isValid ? <BsCheckCircleFill /> : <BsXCircleFill />}
            </ValidationStatus>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </TableWrapper>

        <InfoText>
        {`免责声明：地址通过您本地计算生成,原理与所有区块链地址创建方式相同,信息仅存储在您的浏览器临时内存中，建议在断网环境下使用此工具，请您自行承担因使用该工具而产生的风险`}
      </InfoText>

        <BottomRow>
          <InputGroup>
            <StyledInput
              value={walletCount}
              onUserInput={handleWalletCountChange}
              placeholder="请输入生成数量"
            />
          </InputGroup>
          <ButtonGroup>
          <CustomButton 
            $primary 
            onClick={generateWallets} 
            disabled={!isWeb3Loaded}
          >
            {isWeb3Loaded ? '开始生成' : '加载中...'}
          </CustomButton>
          <CustomButton 
            $generated={isGenerated}
            onClick={copyAllAddresses} 
            disabled={!isGenerated}
          >
            {copyAddressSuccess ? '复制成功' : '复制全部地址'}
          </CustomButton>
          <CustomButton 
            $generated={isGenerated}
            onClick={copyAllPrivateKeys} 
            disabled={!isGenerated}
          >
            {copyPrivateKeySuccess ? '复制成功' : '复制全部私钥'}
          </CustomButton>
        </ButtonGroup>
        </BottomRow>

        {error && <ErrorMessage>{error}</ErrorMessage>}

      </StyledCard>
    </Wrapper>
  );
};

export default BulkWalletGeneration;