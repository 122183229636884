//chainLogic.ts

import { useState, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'
import axios from 'axios'

// RPC 节点地址
const RPC_URLS = {
  ETH: `https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
  BSC: `https://bsc-dataseed.binance.org/`
}

// Moralis
const MORALIS_API_BASE = 'https://deep-index.moralis.io/api/v2.2';
const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjRkYmRkZmE5LTYxZDUtNDg1My1hYzRhLTY5NTRiYTBhODFjZiIsIm9yZ0lkIjoiNDEwOTQ0IiwidXNlcklkIjoiNDIyMzAyIiwidHlwZUlkIjoiNWUxZDRiN2YtZTc3NS00ZDQ0LTk4MTAtYjNjNzg2Yjk5MWM4IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjgzODYzNTAsImV4cCI6NDg4NDE0NjM1MH0.BaYrSrphPqhXpDr813aVJiu6j0hQ7CZmghJ6yqnhOmg';

interface Token {
  address: string
  name: string
  symbol: string
  decimals: number
  isNative: boolean
}

interface Balance {
  tokenBalance: string
  nativeBalance: string
  tokenValue: string
  nativeValue: string
}

// 格式化金额到四位小数
const formatToFourDecimals = (value: string): string => {
  const parts = value.split('.')
  if (parts.length === 2) {
    return `${parts[0]}.${parts[1].slice(0, 6)}`
  }
  return value
}

// 原生代币地址
const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
const WBNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';

// 获取代币价格
const fetchTokenPrice = async (chainId: string, tokenAddress: string): Promise<number> => {
  try {
    let apiTokenAddress = tokenAddress;
    
    // 处理原生代币
    if (tokenAddress.toLowerCase() === 'eth') {
      apiTokenAddress = WETH_ADDRESS;
    } else if (tokenAddress.toLowerCase() === 'bnb') {
      apiTokenAddress = WBNB_ADDRESS;
    }

    const response = await axios.get(`${MORALIS_API_BASE}/erc20/${apiTokenAddress}/price`, {
      params: {
        chain: chainId,
        exchange: chainId === 'eth' ? 'uniswapv2' : 'pancakeswapv2'
      },
      headers: {
        'X-API-Key': MORALIS_API_KEY
      }
    });

    console.log('Moralis API 响应:', response.data);

    if (!response.data || typeof response.data.usdPrice !== 'number') {
      console.error('API 响应结构无效:', response.data);
      return 0;
    }

    return response.data.usdPrice;
  } catch (error) {
    console.error('获取价格时出错:', error);
    if (error && typeof error === 'object' && 'response' in error) {
      console.error('请求错误详情:', (error as any).response?.data);
    }
    return 0;
  }
};

// 代币总价值
export const useChainLogic = () => {
  const [selectedChain, setSelectedChain] = useState<'ETH' | 'BSC'>('ETH')
  const [balances, setBalances] = useState<Record<string, Balance>>({})
  const [tokens, setTokens] = useState<Record<string, Token>>({})
  const [selectedToken, setSelectedToken] = useState('')

  const getProvider = useMemo(() => {
    return new ethers.providers.JsonRpcProvider(RPC_URLS[selectedChain])
  }, [selectedChain])

  const resetChain = (newChain: 'ETH' | 'BSC') => {
    const nativeToken: Token = newChain === 'ETH'
      ? { address: 'ETH', name: 'Ethereum', symbol: 'ETH', decimals: 18, isNative: true }
      : { address: 'BNB', name: 'Binance Coin', symbol: 'BNB', decimals: 18, isNative: true }
    
    setTokens({ [nativeToken.address]: nativeToken })
    setSelectedToken(nativeToken.address)
    setBalances(prevBalances => {
      const newBalances: Record<string, Balance> = {}
      Object.keys(prevBalances).forEach(address => {
        newBalances[address] = { 
          nativeBalance: prevBalances[address]?.nativeBalance || '0',
          tokenBalance: '0',
          nativeValue: '0',
          tokenValue: '0'
        }
      })
      return newBalances
    })
  }

  useEffect(() => {
    resetChain(selectedChain)
  }, [selectedChain])

  const handleChainChange = (newChain: 'ETH' | 'BSC') => {
    setSelectedChain(newChain)
  }

   // 添加代币
  const addToken = async (contractAddress: string) => {
    const tokenContract = new ethers.Contract(
      contractAddress,
      ['function name() view returns (string)', 'function symbol() view returns (string)', 'function decimals() view returns (uint8)'],
      getProvider
    )

    const [name, symbol, decimals] = await Promise.all([
      tokenContract.name(),
      tokenContract.symbol(),
      tokenContract.decimals()
    ])

    setTokens(prevTokens => ({
      ...prevTokens,
      [contractAddress]: { address: contractAddress, name, symbol, decimals, isNative: false }
    }))

    setBalances(prevBalances => {
      const newBalances = { ...prevBalances }
      Object.keys(newBalances).forEach(address => {
        newBalances[address] = {
          ...newBalances[address],
          tokenBalance: '0',
          tokenValue: '0'
        }
      })
      return newBalances
    })

    return { name, symbol, decimals }
  }

   // 获取代币余额
  const fetchBalances = async (addresses: string[], tokenAddress: string) => {
    const nativeToken = selectedChain === 'ETH' ? 'ETH' : 'BNB'
    const chainId = selectedChain === 'ETH' ? 'eth' : 'bsc'
    console.log(`正在获取 ${nativeToken} 和代币地址: ${tokenAddress} 的余额`);
  
    let nativePrice = 0;
    let tokenPrice = 0;
    const tokenSymbol = tokens[tokenAddress]?.symbol || 'Unknown';
  
    try {
      nativePrice = await fetchTokenPrice(chainId, nativeToken)
      console.log(`原生代币 (${nativeToken}) 价格: ${nativePrice}`);
    } catch (error) {
      console.warn(`无法获取原生代币 ${nativeToken} 的价格:`, error);
    }
    
    if (tokenAddress !== nativeToken) {
      try {
        tokenPrice = await fetchTokenPrice(chainId, tokenAddress)
        console.log(`代币 (${tokenSymbol}) 价格: ${tokenPrice}`);
      } catch (error) {
        console.warn(`无法获取代币 ${tokenSymbol} 的价格:`, error);
      }
    } else {
      tokenPrice = nativePrice;
    }
  
    const fetchedBalances = await Promise.all(
      addresses.map(async (address) => {
        let tokenBalance = '0'
        let nativeBalance = '0'
  
        nativeBalance = formatToFourDecimals(ethers.utils.formatEther(await getProvider.getBalance(address)))
  
        if (tokenAddress !== nativeToken) {
          const tokenContract = new ethers.Contract(
            tokenAddress,
            ['function balanceOf(address) view returns (uint256)'],
            getProvider
          )
          const balance = await tokenContract.balanceOf(address)
          tokenBalance = formatToFourDecimals(ethers.utils.formatUnits(balance, tokens[tokenAddress].decimals))
        } else {
          tokenBalance = nativeBalance
        }
  
        const nativeValue = nativePrice ? (parseFloat(nativeBalance) * nativePrice).toFixed(2) : 'N/A'
        const tokenValue = tokenPrice ? (parseFloat(tokenBalance) * tokenPrice).toFixed(2) : 'N/A'
  
        if (nativeValue === 'N/A') {
          console.warn(`无法计算原生代币 ${nativeToken} 的价值`);
        }
        if (tokenValue === 'N/A' && tokenAddress !== nativeToken) {
          console.warn(`无法计算代币 ${tokenSymbol} 的价值`);
        }
  
        return { 
          address, 
          balance: { 
            tokenBalance, 
            nativeBalance, 
            tokenValue, 
            nativeValue 
          } 
        }
      })
    )
  
    setBalances(prevBalances => {
      const newBalances = { ...prevBalances }
      fetchedBalances.forEach(({ address, balance }) => {
        newBalances[address] = balance
      })
      return newBalances
    })
  }

  return {
    selectedChain,
    handleChainChange,
    balances,
    fetchBalances,
    addToken,
    tokens,
    selectedToken,
    setSelectedToken,
    getProvider,
    fetchTokenPrice
  }
}