// MultiSenderConfirmation.tsx

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { ethers, Contract, ContractTransaction } from 'ethers';
import { ButtonPrimary, ButtonGray } from '../Button';
import Loader from './Loader';
import { ShieldCheck, ShieldX, InfoCircle, ExclamationCircle, Eye } from 'react-bootstrap-icons';

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem auto 0;
  width: 50%;
  gap: 1rem;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

const StyledGrayButton = styled(ButtonGray)``;

const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem 0 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.text2};
  font-size: 0.9rem;
`;


const Value = styled.span`
  color: ${({ theme }) => theme.text1};
  font-size: 0.9rem;
`;

const TxHash = styled.a`
  color: ${({ theme }) => theme.primary1};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;


const TxTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TxTableHeader = styled.th`
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
`;

const TxTableCell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
`;

const TxStatus = styled.span<{ status: TransactionStatus }>`
  color: ${({ status, theme }) => {
    switch (status) {
      case 'pending':
        return theme.primary1;
      case 'completed':
        return theme.green1;
      case 'failed':
        return theme.red1;
      default:
        return theme.text1;
    }
  }};
`;

const ProgressContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  height: 300px;
  overflow-y: auto;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 2rem;
  border-radius: 20px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  
  @media (max-width: 768px) {
    width: 90%;
    padding: 1rem;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.text2};
  font-size: 1.2rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CloseButton = styled(ButtonGray)`
  display: flex;
  justify-content: center;
  margin: 1.5rem auto 0;
  width: 80%;
  
  @media (max-width: 768px) {
    padding: 0.3rem 0.8rem;
    font-size: 0.8rem;
  }
`;

const InfoText = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.green1};
  padding: 0.25rem 0.35rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const ModeSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  position: relative;
`;

const ModeButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  border: 2px solid ${({ theme, active }) => active ? theme.bg4 : theme.bg3};
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme, active }) => active ? theme.green1 : theme.text1};

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`;

const IconWrapper = styled.span<{ active: boolean }>`
  margin-right: 0.3rem;
  color: ${({ theme, active }) => active ? theme.green1 : theme.text2};
`;



const ModeButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(InfoCircle)<{ active: boolean }>`
  cursor: pointer;
  color: ${({ theme, active }) => active ? theme.green1 : theme.red1};
  margin-right: 0.5rem;
`;

const InfoPopup = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  padding: 0.75rem;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.text1};
`;

const ErrorToast = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.red1};
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  width: auto;
  text-align: center;
`;

const ErrorIcon = styled(ExclamationCircle)`
  flex-shrink: 0;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: auto;
`;

type TransactionStatus = 'pending' | 'completed' | 'failed' | 'rejected';

const MULTISEND_ABI = [
    "function multisendToken(address token, bool ensureExactAmount, address[] calldata targets, uint256[] calldata amounts) external payable",
    "function multisendEther(address[] calldata targets, uint256[] calldata amounts) public payable"
  ];
  
  //需要授权的合约地址
  const BSC_CONTRACT_ADDRESS = "0x5D00661EA3c9b8f095520573b9B940B6fEbcfD8b";
  const ETH_CONTRACT_ADDRESS = "0xB803b0E5E7457B135085E896FD7A3398b266cd43";
  const SEPOLIA_CONTRACT_ADDRESS = "0x6CF74d5c417d1beBBf4969F1734e1c27c4E39709";

 //发送前检查授权
const ERC20_ABI = [
  "function allowance(address owner, address spender) view returns (uint256)"
];
  
  interface Props {
    tokenAddress: string;
    tokenName: string;
    tokenBalance: string;
    recipients: { address: string; amount: string }[];
    tokenDecimals: number;
    handlePreviousStep: () => void;
    isNativeCoin: boolean;
    nativeCurrency: string;
  }
  
  const MultiSenderConfirmation: React.FC<Props> = ({ 
    tokenAddress, 
    tokenName,
    tokenBalance,
    recipients, 
    tokenDecimals,
    handlePreviousStep,
    isNativeCoin,
    nativeCurrency
  }) => {
    const { account, chainId, library } = useWeb3React();
    const [userNativeBalance, setNativeBalance] = useState<string>('0');
    const [transactions, setTransactions] = useState<{ batchIndex: number; hash: string; status: TransactionStatus }[]>([]);
    const [isSending, setIsSending] = useState(false);
  
    const contractAddress = chainId === 1 ? ETH_CONTRACT_ADDRESS : 
                        chainId === 11155111 ? SEPOLIA_CONTRACT_ADDRESS :
                        BSC_CONTRACT_ADDRESS;

  
    const totalTokenAmount = recipients.reduce((sum, r) => sum + parseFloat(r.amount), 0);
    const [showModal, setShowModal] = useState(false);
    const [showWheelAnimation, setShowWheelAnimation] = useState(false);
    const [isSafeMode, setIsSafeMode] = useState(true);
    //批次设定
    const batchSize = 200;
    const batchCount = Math.ceil(recipients.length / batchSize);

    //模式说明
    const infoRef = useRef<HTMLDivElement>(null);
    const [showInfoPopup, setShowInfoPopup] = useState(false);

    //授权检查
    const [isApproved, setIsApproved] = useState(true);
    
    //错误提示
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showDeniedToast, setShowDeniedToast] = useState(false);

    // 处理代币错误
    const handleSpecificError = (error: unknown) => {
      console.log("Received error:", error); // 添加这行来记录完整的错误对象
    
      if (
        error && 
        typeof error === 'object' && 
        'data' in error
      ) {
        const errorObj = error as { data?: { message?: string, data?: { message?: string } } };
        const errorMessage = errorObj.data?.message || errorObj.data?.data?.message;
    
        if (
          errorMessage &&
          typeof errorMessage === 'string' &&
          errorMessage.includes('Not enough tokens were transfered')
        ) {
          setShowErrorToast(true);
          setTimeout(() => setShowErrorToast(false), 5000); // 5秒后隐藏提示
        }
      }
    };

    // 处理交易被拒绝
    const handleTransactionDenied = () => {
      setShowDeniedToast(true);
      setTimeout(() => setShowDeniedToast(false), 2000); // 2秒后隐藏提示
    };

    // 检查授权状态
   const checkAllowance = async () => {
    if (isNativeCoin) {
      setIsApproved(true);
      return;
    }

    const tokenContract = new Contract(tokenAddress, ERC20_ABI, library.getSigner());
    const allowance = await tokenContract.allowance(account, contractAddress);
    const totalAmount = ethers.utils.parseUnits(totalTokenAmount.toString(), tokenDecimals);
    setIsApproved(allowance.gte(totalAmount));
   };


  // 信息提示钩子
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (infoRef.current && !infoRef.current.contains(event.target as Node)) {
        setShowInfoPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const infoText = isSafeMode
    ? "安全模式：确保每个接收者都能收到精确的代币数量，但无法发送特殊转账机制代币，如果您无法唤起安全模式交易，可能您的代币带有特殊转账机制，请选择标准模式。"
    : "标准模式：适合带税的代币转账，卖不干净的代币转账，及更便宜的GAS费用，如果您需要保证每位接收者精确收到代币数量，请选择安全模式。";

  
    useEffect(() => {
      if (account && library) {
        library.getBalance(account).then((balance: ethers.BigNumber) => {
          setNativeBalance(ethers.utils.formatEther(balance));
        });
      }
    }, [account, library]);

    const sendAllTransactions = async () => {
      setIsSending(true);
      setShowWheelAnimation(true);
      setTransactions([]);
      let anyTransactionConfirmed = false;
    
      try {
        const contract = new Contract(contractAddress, MULTISEND_ABI, library.getSigner()) as Contract & {
          multisendToken: (token: string, ensureExactAmount: boolean, targets: string[], amounts: ethers.BigNumber[]) => Promise<ContractTransaction>,
          multisendEther: (targets: string[], amounts: ethers.BigNumber[], overrides?: ethers.PayableOverrides) => Promise<ContractTransaction>
        };
    
        const transactionPromises: Promise<void>[] = [];
    
        // 提取执行交易的函数，以捕获循环变量
        const executeTransaction = (i: number) => {
          const batchRecipients = recipients.slice(i * batchSize, (i + 1) * batchSize);
          const addresses = batchRecipients.map(r => r.address);
          const amounts = batchRecipients.map(r => ethers.utils.parseUnits(r.amount, isNativeCoin ? 18 : tokenDecimals));
    
          let txPromise;
          if (isNativeCoin) {
            const totalAmount = amounts.reduce((sum, amount) => sum.add(amount), ethers.BigNumber.from(0));
            txPromise = contract.multisendEther(addresses, amounts, { value: totalAmount });
          } else {
            txPromise = contract.multisendToken(tokenAddress, isSafeMode, addresses, amounts);
          }
    
          txPromise = txPromise
          .then((tx: ContractTransaction) => {
            setShowWheelAnimation(false);
            setShowModal(true);
            anyTransactionConfirmed = true;
            setTransactions(prev => [...prev, { batchIndex: i, hash: tx.hash, status: 'pending' }]);
            return tx.wait();
          })
          .then(() => {
            // 仅更新 pending 状态的交易
            setTransactions(prev => prev.map(t =>
              t.batchIndex === i && t.status === 'pending'
                ? { ...t, status: 'completed' }
                : t
            ));
          })
          .catch((error) => {
            console.error(`Error in batch ${i}:`, error);
            handleSpecificError(error);
            setTransactions(prev => {
              const existingTx = prev.find(t => t.batchIndex === i);
              if (existingTx) {
                // 仅更新 pending 状态的交易为 failed
                return prev.map(t =>
                  t.batchIndex === i && t.status === 'pending'
                    ? { ...t, status: 'failed' }
                    : t
                );
              } else {
                if (
                  error.code === 4001 ||
                  (error.message && error.message.includes("User denied transaction signature")) ||
                  (error.message && error.message.includes("MetaMask Tx Signature: User denied transaction signature"))
                ) {
                  handleTransactionDenied();
                  return [...prev, { batchIndex: i, hash: '', status: 'rejected' }];
                } else {
                  return [...prev, { batchIndex: i, hash: '', status: 'failed' }];
                }
              }
            });
            throw error;
          });
  
        transactionPromises.push(txPromise);
      };
  
      for (let i = 0; i < batchCount; i++) {
        executeTransaction(i);
      }
    
        // 等待所有交易被MetaMask排队
        await Promise.all(transactionPromises.map(p => p.catch(e => {
          console.error('Transaction failed:', e);
          // 这里不需要做任何事暂留
        })));
    
      } catch (error) {
        console.error('Error sending transactions:', error);
      } finally {
        setShowWheelAnimation(false);
        setIsSending(false);
        // 只有在没有任何交易被确认的情况下才关闭 modal
        if (!anyTransactionConfirmed) {
          setShowModal(false);
        }
        await checkAllowance();
      }
    };
      
      const handleCloseModal = () => {
        setShowModal(false);
      };

  
      const getExplorerLink = (hash: string) => {
        switch (chainId) {
          case 1:
            return `https://etherscan.io/tx/${hash}`;
          case 11155111:
            return `https://sepolia.etherscan.io/tx/${hash}`;
          default:
            return `https://bscscan.com/tx/${hash}`;
        }
      };

    return (
        <Container>
        <InfoContainer>
      <InfoItem>
        <Label>币种名称：</Label>
        <Value>{isNativeCoin ? nativeCurrency : tokenName}</Value>
         </InfoItem>
        <InfoItem>
       <Label>需要发送的数量：</Label>
          <Value>{totalTokenAmount.toFixed(6)}</Value>
         </InfoItem>
       <InfoItem>
         <Label>您的余额：</Label>
        <Value>{isNativeCoin ? parseFloat(userNativeBalance).toFixed(6) : parseFloat(tokenBalance).toFixed(6)}</Value>
       </InfoItem>
        {!isNativeCoin && (
        <InfoItem>
         <Label>您的{nativeCurrency}余额：</Label>
          <Value>{parseFloat(userNativeBalance).toFixed(4)} {nativeCurrency}</Value>
       </InfoItem>
        )}
         <InfoItem>
        <Label>构建交易数：</Label>
         <Value>需要确认{batchCount}次交易</Value>
         </InfoItem>
         
         <InfoItem>
          <Label>交易记录：</Label>
          <IconButton onClick={() => setShowModal(true)}>
          <Eye size={18} />
        </IconButton>
         </InfoItem>
      </InfoContainer>

      <ModeSelectorContainer>
        <ModeButtonGroup>
          <InfoIconWrapper>
            <InfoIcon size={18} onClick={() => setShowInfoPopup(!showInfoPopup)} 
            active={isSafeMode}
            />
            {showInfoPopup && (
              <InfoPopup ref={infoRef}>
                {infoText}
              </InfoPopup>
            )}
          </InfoIconWrapper>
          <ModeButton 
            active={isSafeMode} 
            onClick={() => setIsSafeMode(true)}
          >
            <IconWrapper active={isSafeMode}>
              <ShieldCheck size={18} />
            </IconWrapper>
            安全模式
          </ModeButton>
        </ModeButtonGroup>
        <ModeButton 
          active={!isSafeMode} 
          onClick={() => setIsSafeMode(false)}
        >
          <IconWrapper active={!isSafeMode}>
            <ShieldX size={18} />
          </IconWrapper>
          标准模式
        </ModeButton>
      </ModeSelectorContainer>

      <InfoText>
        提示：特殊转账机制代币请使用标准模式
      </InfoText>

       <ButtonGroup>
        <StyledGrayButton onClick={handlePreviousStep} disabled={isSending}>
          返回
        </StyledGrayButton>
        {isApproved ? (
          <ButtonPrimary onClick={sendAllTransactions} disabled={isSending}>
            {isSending ? '发送中...' : '发送交易'}
         </ButtonPrimary>
        ) : (
          <StyledGrayButton disabled>
            授权不足
          </StyledGrayButton>
        )}
      </ButtonGroup>

      {showWheelAnimation && (
        <LoaderOverlay>
          <Loader />
        </LoaderOverlay>
      )}

      {showModal && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <ModalTitle>交易进度</ModalTitle>
            </ModalHeader>
            <ProgressContainer>
              <TxTable>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '60%' }} />
                  <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <TxTableHeader>批次</TxTableHeader>
                    <TxTableHeader>交易TX</TxTableHeader>
                    <TxTableHeader>状态</TxTableHeader>
                  </tr>
                </thead>
                <tbody>
                 {transactions.map((tx, index) => (
                <tr key={index}>
            <TxTableCell>{tx.batchIndex + 1}</TxTableCell>
            <TxTableCell>
              {tx.status === 'rejected' ? (
                  '用户拒绝该交易'
               ) : tx.hash ? (
                 <TxHash href={getExplorerLink(tx.hash)} target="_blank" rel="noopener noreferrer">
                   {tx.hash.slice(0, 6)}...{tx.hash.slice(-4)}
                 </TxHash>
               ) : (
                 '-'
               )}
            </TxTableCell>
            <TxTableCell>
            <TxStatus status={tx.status}>
              {tx.status === 'pending' ? '发送中' : 
              tx.status === 'rejected' ? '已拒绝' :
              tx.status === 'completed' ? '已完成' : '失败'}
            </TxStatus>
            </TxTableCell>
            </tr>
               ))}
              </tbody>
              </TxTable>
            </ProgressContainer>
            <CloseButton onClick={handleCloseModal}>关闭</CloseButton>
          </ModalContent>
        </Modal>
      )}
      {showErrorToast && (
        <ErrorToast>
          <ErrorIcon size={20} />
          由于代币有税费或特殊机制。请尝试切换到标准模式重新发送。
        </ErrorToast>
      )}
      {showDeniedToast && (
      <ErrorToast>
        <ErrorIcon size={20} />
        您已拒绝交易
      </ErrorToast>
      )}
    </Container>
  );
};

export default MultiSenderConfirmation;