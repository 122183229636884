import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { ButtonPrimary, ButtonSecondary } from '../Button'
import { AutoColumn } from '../Column'
import { Input } from '../NumericalInput'
import Card from '../../components/Card'
import Modal from '../Modal'
import { ethers } from 'ethers'
import { useChainLogic } from './chainLogic'
import { useCollectionLogic } from './CollectionLogic'
import AddTokenModal from './AddTokenModal'
import AddressInput from './AddressInput';
import TermsOfService from './TermsOfService'
import Toast from './Toast'



const Wrapper = styled.div`
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
  padding: 0.2rem;t
`

const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.bg1};
  padding: 1.5rem;
  border-radius: 20px;
`

const TopButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 1rem;
`

const StyledButton = styled(ButtonSecondary)`
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
  max-width: 200px;
    
  @media (max-width: 480px) {
    font-size: 0.6rem;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
  }
`

const DangerButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.red3};
  color: white;
  border-radius: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.red2};
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
`


const TableHeader = styled.th`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.bg2};
  text-align: left;
  padding: 0.75rem 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-weight: 500;
  z-index: 1;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.6rem 0.4rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
    padding: 0.5rem 0.3rem;
  }
`

const TableCell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  background-color: ${({ theme }) => theme.bg1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.4rem 0.3rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
    padding: 0.3rem 0.2rem;
  }
`

const TableContainer = styled.div`
  max-height: 300px;
  height: 300px;
  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`


const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 0.5rem;
`

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const InputLabel = styled.span`
  width: 110px;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0.3rem;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 110px);
  max-width: 600px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledInput = styled(Input)`
  flex-grow: 1;
  height: 2rem;
  padding: 0.3rem;
  border-radius: 8px;
  font-size: 0.875rem;
  
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};

  &:focus {
    border-color: ${({ theme }) => theme.primary1};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text3};
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
    margin-bottom: 0.5rem;
  }
`

const ActionButton = styled(ButtonSecondary)`
  font-size: 0.9rem;
  white-space: nowrap;
  max-width: 200px;
  border-radius: 8px;
  height: 2.5rem;
  padding: 0 0.6rem;
  margin-left: 0.5rem;
`

const ActionButton2 = styled(ButtonSecondary)<{ $isActive?: boolean }>`
  font-size: 0.9rem;
  white-space: nowrap;
  max-width: 200px;
  border-radius: 8px;
  height: 2.5rem;
  padding: 0 0.6rem;
  margin-left: 0.5rem;
  background-color: ${({ theme, $isActive }) => 
    $isActive ? theme.primary1 : theme.bg1};
  color: ${({ theme, $isActive }) => 
    $isActive ? theme.white : theme.primary2};

  &:hover {
    background-color: ${({ theme, $isActive }) => 
      $isActive ? theme.primary2 : theme.bg1};
  }
`

const InfoText = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.green1};
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 0.5rem;
`

const ExecuteButton = styled(ButtonPrimary)`
  display: flex;
  justify-content: center;
  width: 20%;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
  }
`
const ChainSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const ChainLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;

    @media (max-width: 480px) {
    width: 16px;
    height: 16px;
  }margin-right: 4px;
`

const ChainSelect = styled.select`
  height: 2.5rem;
  width: auto;
  padding: 0 0.6rem;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary1};
  }
`

const TableFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  background-color: ${({ theme }) => theme.bg2};
  border-top: 1px solid ${({ theme }) => theme.bg3};
`

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
`

const FooterLabel = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.6rem;
    width: 100%;
    margin-bottom: 0.25rem;
  }
`

const FooterValue = styled.span`
  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.85rem;
  }
`

const FooterValuePrice = styled(FooterValue)`
  color: green;
  margin-left: 0.5rem;

  @media (max-width: 480px) {
    margin-left: 0.25rem;
  }
`

const TableWrapper = styled.div`
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  overflow: hidden;
`

// 修改 TokenSelectWrapper
const TokenSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

const TokenSelect = styled.select`
  width: 100%;
  height: 2.5rem;
  padding: 0 0.6rem 0 2.5rem; // 左侧padding增加，为LOGO留出空间
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
  border: 1px solid ${({ theme }) => theme.bg3};
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary1};
  }
`

// 修改 TokenLogo
const TokenLogo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

// 钱包导入MODEL
const ModalContent = styled.div`
  padding: 10px;
  width: 420px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg1};
`

const ModalTitle = styled.h2`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.5rem;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.2rem;
    text-align: center;
  }
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 8px;
  font-size: 1rem;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary1};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const CancelButton = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};

  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.red1};
  margin-bottom: 1rem;
`
// 钱包导入MODEL END



// 钱包导入主逻辑

interface Wallet {
  id: number
  address: string
  privateKey: string
}

interface ImportWalletModalProps {
  isOpen: boolean
  onDismiss: () => void
  onImport: (wallets: Omit<Wallet, 'id'>[]) => void
}

// 钱包导入MODEL
const ImportWalletModal: React.FC<ImportWalletModalProps> = ({ isOpen, onDismiss, onImport }) => {
  const [privateKeys, setPrivateKeys] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {  // 监听变化，关闭弹窗时清空输入框
    if (isOpen) {
      setPrivateKeys('')
      setError(null)
    }
  }, [isOpen])

  const handleImport = async () => {
    if (!privateKeys.trim()) {
      setError('请输入私钥')
      return
    }
    setError(null)
    setIsLoading(true)
    try {
      const keys = privateKeys.split('\n').filter(key => key.trim() !== '')
      const wallets = keys.map(key => {
        try {
          // 如果私钥没有 '0x' 前缀，添加它
          const formattedKey = key.trim().startsWith('0x') ? key.trim() : `0x${key.trim()}`
          const wallet = new ethers.Wallet(formattedKey)
          return { address: wallet.address, privateKey: formattedKey }
        } catch (error) {
          console.error('Invalid private key:', key)
          return null
        }
      }).filter((wallet): wallet is Omit<Wallet, 'id'> => wallet !== null)

      if (wallets.length === 0) {
        throw new Error('没有有效的私钥')
      }

      onImport(wallets)
      onDismiss()
    } catch (error) {
      if (error instanceof Error) {
        setError('导入钱包失败: ' + error.message)
      } else {
        setError('导入钱包失败: 未知错误')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} minHeight={false} maxHeight={90}>
      <ModalContent>
        <ModalTitle>导入钱包</ModalTitle>
        <StyledTextArea
          value={privateKeys}
          onChange={(e) => {
            setPrivateKeys(e.target.value)
            setError(null)
          }}
          placeholder="请输入私钥，每行一个"
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonContainer>
          <CancelButton onClick={onDismiss}>取消</CancelButton>
          <ButtonPrimary onClick={handleImport} disabled={isLoading}>
            {isLoading ? '导入中...' : '导入钱包'}
          </ButtonPrimary>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
}

// 钱包导入主逻辑 END


const TokenAggregation: React.FC = () => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isAddTokenModalOpen, setIsAddTokenModalOpen] = useState(false)
  const [wallets, setWallets] = useState<Wallet[]>([])
  const [selectedWallets, setSelectedWallets] = useState<Set<number>>(new Set())
  const [selectAll, setSelectAll] = useState(false)
  const [nextId, setNextId] = useState(1)

  const [gasPrice, setGasPrice] = useState('')
  const [gasLimit, setGasLimit] = useState(21000)
  const [gasCost, setGasCost] = useState<{ nativeCost: string; usdCost: string }>({ nativeCost: '0', usdCost: '0' })
  const [sendAmount, setSendAmount] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [isCollecting, setIsCollecting] = useState(false)
  const [toast, setToast] = useState<{ message: string; isSuccess: boolean } | null>(null)

  // 状态类型，代币转账结果
  type CollectionResult = {
    tx: string;
    status: 'pending' | 'success' | 'failed';
    error?: string;
  };

  const [collectionResults, setCollectionResults] = useState<Record<number, CollectionResult>>({});

  const {
    selectedChain,
    handleChainChange,
    balances,
    fetchBalances,
    addToken,
    tokens,
    selectedToken,
    setSelectedToken,
    getProvider
  } = useChainLogic()

  const { getGasPrice, calculateGasCost, transferTokens } = useCollectionLogic(getProvider, selectedChain)

  useEffect(() => {
    setSelectAll(selectedWallets.size === wallets.length && wallets.length > 0)
  }, [selectedWallets, wallets])

  const regenerateIds = (walletsToUpdate: Wallet[]) => {
    return walletsToUpdate.map((wallet, index) => ({
      ...wallet,
      id: index + 1
    }))
  }

  const handleImportWallet = (importedWallets: Omit<Wallet, 'id'>[]) => {
    const newWallets = importedWallets.filter(
      wallet => !wallets.some(existingWallet => existingWallet.address === wallet.address)
    ).map((wallet, index) => ({
      ...wallet,
      id: nextId + index
    }));

    if (newWallets.length < importedWallets.length) {
      setToast({ message: `已导入 ${newWallets.length} 个新钱包。${importedWallets.length - newWallets.length} 个钱包已存在。`, isSuccess: true })
    }

    setWallets(prevWallets => regenerateIds([...prevWallets, ...newWallets]))
    setNextId(nextId + newWallets.length)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedWallets(new Set(wallets.map(w => w.id)))
      setSelectAll(true)
    } else {
      setSelectedWallets(new Set())
      setSelectAll(false)
    }
  }

  const handleSelectWallet = (id: number) => {
    const newSelected = new Set(selectedWallets)
    if (newSelected.has(id)) {
      newSelected.delete(id)
    } else {
      newSelected.add(id)
    }
    setSelectedWallets(newSelected)
    setSelectAll(newSelected.size === wallets.length)
  }

  const handleRefreshBalance = async () => {
    if (!selectedToken) {
      setToast({ message: '请先选择代币', isSuccess: false })
      return
    }
    const selectedAddresses = wallets
      .filter(wallet => selectedWallets.has(wallet.id))
      .map(wallet => wallet.address)
    
    if (selectedAddresses.length === 0) {
      setToast({ message: '请至少选择一个钱包地址', isSuccess: false })
      return
    }
    
    try {
      await fetchBalances(selectedAddresses, selectedToken)
    } catch (error) {
      console.error('Error refreshing balances:', error)
      setToast({ message: '刷新余额时出错，请稍后再试', isSuccess: false })
    }
  }

  const handleDeleteSelected = () => {
    if (selectedWallets.size === 0) {
      setToast({ message: '请先选择要删除的钱包', isSuccess: false })
      return;
    }

    const newWallets = wallets.filter(wallet => !selectedWallets.has(wallet.id));
    const updatedWallets = regenerateIds(newWallets)
    setWallets(updatedWallets)
    setSelectedWallets(new Set())
    setNextId(updatedWallets.length + 1)

    setToast({ message: `已删除 ${selectedWallets.size} 个钱包`, isSuccess: true })
  }

  const handleAddToken = async (tokenAddress: string) => {
    try {
      const newToken = await addToken(tokenAddress)
      if (newToken && newToken.symbol) {
        setToast({ message: `代币 ${newToken.symbol} 添加成功`, isSuccess: true })
      } else {
        alert('代币添加成功')
      }
    } catch (error) {
      console.error('添加代币失败:', error)
      setToast({ message: '添加代币失败，请检查地址或选择的链是否正确', isSuccess: false })
    }
  }

  const [gasPriceInfo, setGasPriceInfo] = useState<string | null>(null)

  const handleGetGasPrice = async () => {
    try {
      const price = await getGasPrice()
      
      // 将 gwei 字符串转换为数字
      const priceNumber = parseFloat(price)
      
      // 增加 5%
      const increasedPrice = priceNumber * 1.05
      const finalPrice = increasedPrice.toFixed(6)

      setGasPriceInfo(`≈ ${price} gwei ~ ${increasedPrice} gwei`)

      setGasPrice(finalPrice)
    } catch (error) {
      console.error('获取 Gas 价格失败:', error)
      setToast({ message: '获取 Gas 价格失败，请稍后再试', isSuccess: false })
    }
  }

  const handleGetGasLimit = async () => {
    if (!selectedToken) {
      setToast({ message: '请先选择代币', isSuccess: false })
      return
    }
  
    const selectedWalletAddresses = wallets
      .filter(wallet => selectedWallets.has(wallet.id))
      .map(wallet => wallet.address)
  
    if (selectedWalletAddresses.length === 0) {
      setToast({ message: '请至少选择一个钱包地址', isSuccess: false })
      return
    }
  
    try {
      const fromAddress = selectedWalletAddresses[0] // 使用第一个选中的钱包地址模拟limit
      const tokenDecimals = tokens[selectedToken]?.decimals || 18 // 代币精度
  
      // 获取钱包余额
      let balance: ethers.BigNumber
      if (selectedToken === nativeToken) {
        balance = await getProvider.getBalance(fromAddress)
      } else {
        const tokenContract = new ethers.Contract(
          selectedToken,
          ['function balanceOf(address) view returns (uint256)'],
          getProvider
        )
        balance = await tokenContract.balanceOf(fromAddress)
      }
      
  
      // 使用余额的 1% 或最小单位 作为limit估算金额
      const onePercent = balance.div(100)
      const minAmount = ethers.utils.parseUnits('1', tokenDecimals) // 代币精度
      const estimationAmount = onePercent.lt(minAmount) ? onePercent : minAmount
  
      let estimatedGasLimit: ethers.BigNumber
      if (selectedToken === nativeToken) {
        // 估算原生代币转账
        estimatedGasLimit = await getProvider.estimateGas({
          from: fromAddress,
          to: recipientAddress || fromAddress, // 发送给自己
          value: estimationAmount
        })
      } else {
        // 估算代币转账
        const tokenContract = new ethers.Contract(
          selectedToken,
          ['function transfer(address to, uint256 amount)'],
          getProvider
        )
        estimatedGasLimit = await tokenContract.estimateGas.transfer(
          recipientAddress || fromAddress, // 发送给自己
          estimationAmount,
          { from: fromAddress }
        )
      // 自定义代币limit默认翻倍
      const safeGasLimit = estimatedGasLimit.mul(200).div(100).toNumber()

      setGasLimit(safeGasLimit)
      updateGasCost(gasPrice, safeGasLimit)
      }

      // 原生代币默认21000
      const safeGasLimit = estimatedGasLimit.mul(100).div(100).toNumber()

      setGasLimit(safeGasLimit)
      updateGasCost(gasPrice, safeGasLimit)

    } catch (error) {
      console.error('获取 Gas 限制失败:', error)
      
      // 检查是否是余额不足
      if (error instanceof Error && error.message.includes('insufficient funds')) {
        alert('钱包余额不足以支付预估的 gas 费用。')
      } else {
        alert('获取 Gas 限制失败，使用默认值。')
      }
      
      setGasLimit(21000)
      updateGasCost(gasPrice, 21000)

    }
  }

  const updateGasCost = async (price: string, limit: number) => {
    if (price && limit) {
      try {
        const cost = await calculateGasCost(price, limit)
        setGasCost(cost)
      } catch (error) {
        console.error('计算 Gas 成本失败:', error)
        setGasCost({ nativeCost: '0', usdCost: '0' }) // 错误时重置为默认值
      }
    }
  }

  const handleGasLimitChange = (newLimit: string) => {
    const limit = parseInt(newLimit)
    setGasLimit(limit)
    updateGasCost(gasPrice, limit)
  }

  // MAX选中与取消
  const [isMaxSelected, setIsMaxSelected] = useState(false)

  const handleSendAllAmount = () => {
    if (isMaxSelected) {
      setSendAmount('')
      setIsMaxSelected(false)
    } else {
      setSendAmount('max')
      setIsMaxSelected(true)
    }
  }
  const handleCollect = async () => {
    if (!recipientAddress || !gasPrice || !gasLimit) {
      setToast({ message: '请填写所有必要信息', isSuccess: false })
      return
    }
  
    setIsCollecting(true)
    setCollectionResults({})
  
    const nativeToken = selectedChain === 'ETH' ? 'ETH' : 'BNB'
    const tokenDecimals = tokens[selectedToken]?.decimals || 18
  
    // 创建一个包含所有选中钱包的列表，// 每次处理5个钱包
    const selectedWalletsList = wallets.filter(wallet => selectedWallets.has(wallet.id))
    const batchSize = 5
  
    // 映射来存储每个地址的最新 nonce
    const nonceMap: { [address: string]: number } = {}
  
    // 使用 for 循环，每次处理5个钱包
    for (let i = 0; i < selectedWalletsList.length; i += batchSize) {
      // 获取当前批次的钱包
      const batch = selectedWalletsList.slice(i, i + batchSize)
      // 为当前批次的每个钱包创建一个 Promise
      const batchPromises = batch.map(async (wallet) => {
        try {
          // 获取当前 nonce
          if (nonceMap[wallet.address] === undefined) {
            nonceMap[wallet.address] = await getProvider.getTransactionCount(wallet.address, 'pending')
          }
  
          const balance = balances[wallet.address]
          let amountString: string
          let amountBigNumber: ethers.BigNumber
  
          if (sendAmount === 'max') {
            if (selectedToken === nativeToken) {
              amountBigNumber = ethers.utils.parseEther(balance.nativeBalance).sub(ethers.utils.parseUnits(gasPrice, 'gwei').mul(gasLimit))
              if (amountBigNumber.lte(0)) {
                throw new Error('余额不足以支付 gas 费用')
              }
              amountString = ethers.utils.formatEther(amountBigNumber)
            } else {
              amountString = balance.tokenBalance
              amountBigNumber = ethers.utils.parseUnits(amountString, tokenDecimals)
            }
          } else {
            amountString = sendAmount
            amountBigNumber = ethers.utils.parseUnits(amountString, tokenDecimals)
          }
  
          // 检查余额是否足够
          const currentBalance = selectedToken === nativeToken
            ? await getProvider.getBalance(wallet.address)
            : await new ethers.Contract(selectedToken, ['function balanceOf(address) view returns (uint256)'], getProvider).balanceOf(wallet.address)
  
          if (currentBalance.lt(amountBigNumber)) {
            throw new Error('余额不足')
          }
  
          if (selectedToken !== nativeToken) {
            // 检查是否有足够的原生代币支付 gas 费用
            const nativeBalance = await getProvider.getBalance(wallet.address)
            const gasCost = ethers.utils.parseUnits(gasPrice, 'gwei').mul(gasLimit)
            if (nativeBalance.lt(gasCost)) {
              throw new Error(`${nativeToken} 余额不足以支付 gas 费用`)
            }
          }
  
          // 发送交易
          const tx = await transferTokens(
            wallet.address,
            recipientAddress,
            amountString,
            wallet.privateKey,
            selectedToken === nativeToken ? null : selectedToken,
            gasPrice,
            gasLimit,
            tokenDecimals,
            nonceMap[wallet.address]
          )
  
          // 更新 nonce 映射
          nonceMap[wallet.address]++
  
          setCollectionResults(prev => ({
            ...prev,
            [wallet.id]: { tx: tx.hash, status: 'pending' }
          }))
  
          const receipt = await tx.wait()
  
          setCollectionResults(prev => ({
            ...prev,
            [wallet.id]: { tx: tx.hash, status: receipt.status === 1 ? 'success' : 'failed' }
          }))
  
        } catch (error) {
          setCollectionResults(prev => ({
            ...prev,
            [wallet.id]: { 
              tx: '', 
              status: 'failed', 
              error: error instanceof Error ? error.message : '未知错误'
            }
          }))
        }
      })
  
      // 等待当前批次的所有归集操作
      await Promise.all(batchPromises)
    }
  
    setIsCollecting(false)
    handleRefreshBalance()
  }

  const nativeToken = selectedChain === 'ETH' ? 'ETH' : 'BNB'

  const selectedWalletsInfo = useMemo(() => {
    let totalNativeBalance = 0;
    let totalNativeValue = 0;
    let totalTokenBalance = 0;
    let totalTokenValue = 0;

    wallets.forEach(wallet => {
      if (selectedWallets.has(wallet.id)) {
        const walletBalance = balances[wallet.address];
        if (walletBalance) {
          totalNativeBalance += parseFloat(walletBalance.nativeBalance);
          totalNativeValue += parseFloat(walletBalance.nativeValue || '0');
          if (selectedToken !== nativeToken) {
            totalTokenBalance += parseFloat(walletBalance.tokenBalance);
            totalTokenValue += parseFloat(walletBalance.tokenValue || '0');
          }
        }
      }
    });

    return {
      nativeBalance: totalNativeBalance.toFixed(4),
      nativeValue: totalNativeValue.toFixed(2),
      tokenBalance: totalTokenBalance.toFixed(4),
      tokenValue: totalTokenValue.toFixed(2)
    };
  }, [wallets, selectedWallets, balances, selectedToken, nativeToken]);

  const getTokenLogoUrl = useMemo(() => (address: string) => {
    const token = tokens[address];
    if (!token) return `/images/${selectedChain.toLowerCase()}.png`;
  
    if (token.symbol === 'ETH' || token.symbol === 'BNB') {
      return `/images/${token.symbol.toLowerCase()}.png`;
    }
  
    const chainFolder = selectedChain === 'ETH' ? 'eth' : 'bsc';
    return `https://raw.githubusercontent.com/TP-Lab/tokens/master/${chainFolder}/${address}/logo.png`;
  }, [tokens, selectedChain]);


  return (
    <Wrapper>
      {toast && (
      <Toast
        message={toast.message}
        isSuccess={toast.isSuccess}
        onClose={() => setToast(null)}
      />
    )}
    <TermsOfService />
      <StyledCard>
        <TopButtonRow>
          <ChainSelectWrapper>
            <ChainLogo 
              src={`/images/${selectedChain.toLowerCase()}.png`} 
              alt={`${selectedChain} logo`}
            />
            <ChainSelect 
              value={selectedChain} 
              onChange={(e) => handleChainChange(e.target.value as 'ETH' | 'BSC')}
            >
              <option value="ETH">ETH</option>
              <option value="BSC">BSC</option>
            </ChainSelect>
          </ChainSelectWrapper>
          <StyledButton onClick={() => setIsAddTokenModalOpen(true)}>添加代币</StyledButton>
          <StyledButton onClick={() => setIsImportModalOpen(true)}>导入钱包</StyledButton>
          <DangerButton onClick={handleDeleteSelected}>删除选中</DangerButton>
        </TopButtonRow>

        <TableWrapper>
          <Table>
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <thead>
              <tr>
                <TableHeader>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  ID
                </TableHeader>
                <TableHeader>地址</TableHeader>
                <TableHeader>{nativeToken}</TableHeader>
                <TableHeader>{tokens[selectedToken]?.symbol !== nativeToken ? tokens[selectedToken]?.symbol : ''}</TableHeader>
                <TableHeader>TX</TableHeader>
                <TableHeader>结果</TableHeader>
              </tr>
            </thead>
          </Table>
          <TableContainer>
            <Table>
              <colgroup>
                <col style={{ width: '10%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
              </colgroup>
              <tbody>
                {wallets.map((wallet) => (
                  <tr key={wallet.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedWallets.has(wallet.id)}
                        onChange={() => handleSelectWallet(wallet.id)}
                      />
                      {wallet.id}
                    </TableCell>
                    <TableCell>{wallet.address}</TableCell>
                    <TableCell>
                      {balances[wallet.address]?.nativeBalance || '0.0000'}
                      {balances[wallet.address]?.nativeValue && (
                        <span style={{ marginLeft: '3px', color: 'green' }}>
                          (${balances[wallet.address].nativeValue})
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      {selectedToken !== nativeToken
                        ? (
                          <>
                            {balances[wallet.address]?.tokenBalance || '0.0000'}
                            {balances[wallet.address]?.tokenValue && (
                              <span style={{ marginLeft: '3px', color: 'orange' }}>
                                (${balances[wallet.address].tokenValue})
                              </span>
                            )}
                          </>
                        )
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {collectionResults[wallet.id]?.tx ? (
                        <a 
                          href={`https://${selectedChain === 'ETH' ? 'etherscan.io' : 'bscscan.com'}/tx/${collectionResults[wallet.id].tx}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {collectionResults[wallet.id].tx.slice(0, 6)}...{collectionResults[wallet.id].tx.slice(-4)}
                        </a>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {collectionResults[wallet.id]?.status === 'pending' && <span style={{ color: 'orange' }}>归集中...</span>}
                      {collectionResults[wallet.id]?.status === 'success' && <span style={{ color: 'green' }}>成功</span>}
                      {collectionResults[wallet.id]?.status === 'failed' && (
                        <span style={{ color: 'red' }}>
                          失败: {collectionResults[wallet.id].error || '未知错误'}
                        </span>
                        )}
                      {!collectionResults[wallet.id] && '-'}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </TableWrapper>

        <TableFooter>
          <FooterItem>
            <FooterLabel>{nativeToken}~</FooterLabel>
            <FooterValue>{selectedWalletsInfo.nativeBalance}</FooterValue>
            <FooterValuePrice>(${selectedWalletsInfo.nativeValue})</FooterValuePrice>
          </FooterItem>
          {selectedToken !== nativeToken && (
            <FooterItem>
              <FooterLabel>{tokens[selectedToken]?.symbol}~</FooterLabel>
              <FooterValue>{selectedWalletsInfo.tokenBalance}</FooterValue>
              <FooterValuePrice>(${selectedWalletsInfo.tokenValue})</FooterValuePrice>
            </FooterItem>
          )}
        </TableFooter>

        <InfoText>导入钱包后请先刷新所需代币的余额，再进行归集操作，归集GAS费用将从对应地址支付</InfoText>

        <AutoColumn gap="15px">
          <InputGroup>
            <InputLabel>选择代币:</InputLabel>
            <InputWrapper>
              <TokenSelectWrapper>
                <TokenLogo 
                  src={getTokenLogoUrl(selectedToken)}
                  alt={tokens[selectedToken]?.symbol || 'Token logo'}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src = `/images/${selectedChain.toLowerCase()}.png`;
                  }}
                />
                <TokenSelect 
                  value={selectedToken} 
                  onChange={(e) => setSelectedToken(e.target.value)}
                >
                  {Object.entries(tokens).map(([address, token]) => (
                    <option key={address} value={address}>
                      {token.symbol}
                    </option>
                  ))}
                </TokenSelect>
              </TokenSelectWrapper>
              <ActionButton onClick={handleRefreshBalance}>刷新余额</ActionButton>
            </InputWrapper>
          </InputGroup>

          <InputGroup>
           <InputLabel>Gas价格 (gwei):</InputLabel>
            <InputWrapper>
            <StyledInput value={gasPrice} onUserInput={setGasPrice} placeholder="0" />
            <ActionButton onClick={handleGetGasPrice}>获取最新Gas</ActionButton>
          </InputWrapper>
           {gasPriceInfo && (
            <span style={{ marginLeft: '0.5rem', fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
              {gasPriceInfo}
              </span>
             )}
          </InputGroup>

          <InputGroup>
            <InputLabel>Gas限制 (limit):</InputLabel>
            <InputWrapper>
              <StyledInput value={gasLimit.toString()} onUserInput={handleGasLimitChange} placeholder="10000" />
              <ActionButton onClick={handleGetGasLimit}>获取Gas限制</ActionButton>
            </InputWrapper>
            <span style={{ marginLeft: '0.5rem', fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
              ≈ {gasCost.nativeCost} {nativeToken} (${gasCost.usdCost})
            </span>
          </InputGroup>

          <InputGroup>
           <InputLabel>发送数量:</InputLabel>
           <InputWrapper>
            <StyledInput 
             value={sendAmount} 
              onUserInput={(value) => {
                setSendAmount(value)
                setIsMaxSelected(false)
              }} 
              placeholder="0" 
              disabled={isMaxSelected}
              />
              <ActionButton2 
               onClick={handleSendAllAmount}
               $isActive={isMaxSelected}
               >
                {isMaxSelected ? '取消全部' : '全部数量'}
                </ActionButton2>
              </InputWrapper>
            </InputGroup>

          <InputGroup>
          <InputLabel>接收地址:</InputLabel>
          <InputWrapper>
           <AddressInput
           value={recipientAddress}
           onChange={setRecipientAddress}
           placeholder="输入接收地址"
          />
          </InputWrapper>
        </InputGroup>

        <InfoText>注意：检查接收地址是否正确，归集后请确认归集结果</InfoText>

          <ExecuteButton onClick={handleCollect} disabled={isCollecting}>
            {isCollecting ? '归集中...' : '确认执行'}
          </ExecuteButton>
        </AutoColumn>
      </StyledCard>
      <ImportWalletModal
        isOpen={isImportModalOpen}
        onDismiss={() => setIsImportModalOpen(false)}
        onImport={handleImportWallet}
      />
      <AddTokenModal
        isOpen={isAddTokenModalOpen}
        onDismiss={() => setIsAddTokenModalOpen(false)}
        onAddToken={handleAddToken}
        selectedChain={selectedChain}
      />
    </Wrapper>
  )
}

export default TokenAggregation